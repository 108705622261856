export const productTableColumns = [
  { label: '品牌', prop: 'brandName' },
  { label: 'BU', prop: 'buName' },
  { label: '商品类目', prop: 'categoryName',
    formatter: (row) => {
      return row.categoryNameList?.join('>')
    }
  },
  { label: '季节', prop: 'seasonName' },
  { label: '开款季', prop: 'launchSeasonDictValue' },
  { label: '产品定位', prop: 'positionName' },
  { label: 'Style', prop: 'style' },
  { label: 'Color', prop: 'color' },
  { label: 'Size', prop: 'size' },
  { label: 'SKU', prop: 'sku' },
  { label: '销售渠道', prop: 'categoryName',
    formatter: (row) => {
      return row.platformCode && `${row.platformCode}/${row.siteCode}/${row.shopCode}`
    }
  },
  { label: '标识类型', prop: 'productType' },
  { label: '产品标识', prop: 'productSymbolValue' },
  { label: '更新人', prop: 'modifyByName' },
  { label: '更新时间', prop: 'modifyTime' }
]

export const templateUrl = 'https://aliyun-oa-query-results-1509030252432744-oss-cn-hongkong.oss-cn-hongkong.aliyuncs.com/template/scm/%E4%BA%A7%E5%93%81%E6%A0%87%E8%AF%86%E9%85%8D%E7%BD%AE%E6%A8%A1%E6%9D%BF.xlsx'

export const header = ['序号', '错误信息', 'Style', 'Color', 'Size', 'Sku', '标识类型', '产品标识']
export const filterVal = ['no', 'errorMessage', 'style', 'color', 'size', 'sku', 'productType', 'productSymbolValue']
