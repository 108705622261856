<template>
  <div>
    <QueryForm v-model="queryForm" show-export :query-btn-loading="tableLoading" @handleExport="handleExport" @query="handleQuery" @reset="handleQueryFormReset">
      <el-form-item label="品牌">
        <Select
          v-model="queryForm.brandIdList"
          api-key="brandList"
          clearable
          multiple
        />
      </el-form-item>
      <el-form-item label="商品类目" :class="$i18n.locale">
        <el-cascader
          v-model="queryForm.categoryIdList"
          clearable
          collapse-tags
          filterable
          separator=">"
          :options="basecategorydatas"
          class="style_w100"
          :props="{
            value: 'id',
            label: 'categoryName',
            children: 'sonCategory',
            multiple: true,
          }"
        />
      </el-form-item>
      <el-form-item label="产品定位" :class="$i18n.locale">
        <Select
          v-model="queryForm.positionIdList"
          style="width:100%;"
          api-key="position"
          clearable
          filterable
          collapse-tags
          multiple
        />
      </el-form-item>
      <el-form-item label="季节">
        <Select
          v-model="queryForm.seasonId"
          :select-options="_getAllCommodityDict('SEASON')"
          :configuration=" { key: 'val', label: 'label', value: 'val' }"
          clearable
          multiple
        />
      </el-form-item>
      <el-form-item label="开款季">
        <Select
          v-model="queryForm.launchSeasonDictKeyList"
          api-key="listLaunchSeason"
          clearable
          multiple
        />
      </el-form-item>
      <el-form-item label="标识类型">
        <Select
          v-model="queryForm.productTypeDictList"
          :select-options="_getAllCommodityDict('SCM_PRODUCT_TYPE')"
          :configuration=" { key: 'val', label: 'label', value: 'val' }"
          clearable
          multiple
        />
      </el-form-item>
      <el-form-item label="Style">
        <Select
          v-model="queryForm.styleId"
          api-key="styleList"
          clearable
          @change="handleChangeStyle"
          @responseData="val=>styleList=val"
        />
      </el-form-item>
      <el-form-item label="Color">
        <Select
          v-model="queryForm.colorName"
          api-key="colorList"
          :params="{ styleId : queryForm.styleId }"
          ganged
          :configuration=" { key: 'id', label: 'colorName', value: 'colorName' }"
          not-required-params
        />
      </el-form-item>
      <el-form-item label="Size">
        <Select
          v-model="queryForm.sizeName"
          not-required-params
          api-key="sizeList"
          :params="{ styleId : queryForm.styleId}"
          ganged
          :configuration=" { key: 'id', label: 'size', value: 'size' }"
        />
      </el-form-item>
      <el-form-item label="SKU">
        <el-input v-model="queryForm.skuName" :placeholder="$t('page.inputPlaceholder')" />
      </el-form-item>
    </QueryForm>
    <div>
      <el-button type="primary" class=" mb-2" @click="importDialogVisible=true">批量导入</el-button>
      <el-button type="primary" class=" ml-2" @click="handleDelete">删除</el-button>

    </div>
    <Table
      check
      :table-data="tableData"
      max-height="600px"
      :columns="productTableColumns"
      :loading="tableLoading"
      @handleSelectionChange="handleSelectionChange "
    />
    <Paging :pager="pager" end class="mt-3" @pagination="pagerUpdate" />
    <BatchImport v-model="importDialogVisible" @refresh="handleQuery" />

  </div>
</template>

<script>
import QueryForm from '@/components/QueryForm'
import { productTableColumns } from './components/columns'
import { listByPage, delSymbol, getBasecategory, exportProductSymbol } from '@/api/configuration'
import Table from '@/components/Table'
import Select from '@/components/Selection'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import Paging from '@/components/Pagination'
import BatchImport from './components/BatchImport'
import { handlePageParams } from '@/utils'

export default {
  components: { QueryForm, Table, Select, Paging, BatchImport },
  mixins: [commodityInfoDict],

  data() {
    return {
      idList: [],
      productTableColumns,
      tableData: [],
      importDialogVisible: false,
      queryForm: {
        sizeName: '',
        styleId: '',
        colorName: '',
        categoryIdList: []
      },
      tableLoading: false,
      basecategorydatas: [],
      styleList: [],
      pager: {
        size: 10,
        current: 1
      }
    }
  },
  computed: {
    qeuryParams() {
      return handlePageParams({
        formData: this.queryForm,
        processedParameter: {
          lastCategoryIdList: ['categoryIdList'],
          findNameOrIdList: {
            styleName: ['styleId', 'id', 'styleName', this.styleList]
          }
        }

      })
    }
  },
  mounted() {
    this._getBasecategory()
    this.handleQuery()
  },
  methods: {
    async handleExport() {
      const params = Object.assign({}, this.qeuryParams, { idList: this.idList })
      await exportProductSymbol(params)
      this.$notify({
        message: '操作成功，请前往文件导出界面下载',
        type: 'success'
      })
    },
    async _getBasecategory() {
      const { datas } = await getBasecategory()
      this.basecategorydatas = datas
    },
    handleSelectionChange(val) {
      this.idList = []
      val.map(v => this.idList.push(v.id))
    },
    async handleDelete() {
      if (!this.idList.length) return this.$message.warning('至少选择一条数据')
      this.$confirm(`您选择${this.idList.length}行数据，请确认是否删除，即时生效`, '提示', {
        confirmButtonText: '确定',
        showCancelButton: false,
        type: 'warning'
      }).then(async() => {
        await delSymbol(this.idList)
        this.$notify({
          title: '操作成功',
          message: '操作成功',
          type: 'success'
        })
        this.handleQuery()
      })
    },
    pagerUpdate(val) {
      this.pager = val
      this.handleClick()
    },
    handleChangeStyle() {
      this.queryForm.colorName = ''
      this.queryForm.sizeName = ''
    },
    handleQueryFormReset() {
      this.queryForm = this.$options.data().queryForm
      this.handleQuery()
    },
    handleQuery() {
      this.pager.current = 1
      this.handleClick()
    },
    async handleClick() {
      try {
        this.tableLoading = true
        const { datas: { records, pager }} = await listByPage(this.pager, this.qeuryParams)
        this.tableData = records
        this.pager = pager
      } finally {
        this.tableLoading = false
      }
    }

  }
}
</script>

<style>

</style>
