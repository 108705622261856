<template>
  <div class="data-table mb-3">
    <el-table
      ref="table"
      v-loading="loading"
      :data="tableData"
      :span-method="objectSpanMethod"
      :style="{width:(width?width+'px':'100%')}"
      :header-cell-style="{background:'#fafafa'}"
      :max-height="maxHeight"
      :show-summary="showSummary"
      :summary-method="getSummaries"
      :cell-style="cellStyle"
      :row-key="rowKey"
      :row-class-name="isShowExpend"
      v-bind="$attrs"
      @select="select"
      @selection-change="handleSelectionChange"
      @select-all="selectAll"
      @expand-change="handleTableExpandChange"
    >
      <el-table-column
        v-if="check"
        type="selection"
        width="50"
        :reserve-selection="reserveSelection"
        :selectable="selectInit"
      />
      <el-table-column v-if="expand" type="expand">
        <template slot-scope="props">
          <div class="my_table">
            <el-table
              :ref="'tableChild' + rowKey(props.row) "
              :data="props.row[expandData]"
              :header-cell-class-name="cellClass"
              max-height="400px"
              @selection-change="handleSelectionExpandChange"
              @select="selectExpandData"
            >
              <el-table-column
                v-if="showExpandCheck"
                type="selection"
                width="50"
                :selectable="selectExpandInit"
              />
              <el-table-column
                label="序号"
                type="index"
                width="50"
                align="center"
              />
              <template v-for="(col, idx) in expandColumns">
                <slot v-if="col.slot" :name="col.slot" />
                <el-table-column
                  v-else-if="col.show!==false"
                  :key="idx"
                  :prop="col.prop"
                  :label="col.label"
                  :width="col.width"
                  :formatter="col.formatter"
                  align="center"
                />
                <template v-else-if="col.dynamic===true">
                  <el-table-column
                    v-for="(list,index) in props.row[expandData].length&&props.row[expandData][0][col.columnLabelList]||[]"
                    :key="`${list} && ${index}`"
                    :label="list"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <span>
                        {{ Object.keys(scope.row[col.prop]).length?
                          scope.row[col.prop][list]&&scope.row[col.prop][list][col.dynamicValue]:'' }}
                      </span>
                    </template>
                  </el-table-column>
                </template>
              </template>
            </el-table>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="showIndex"
        label="序号"
        type="index"
        width="50"
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="pageObj.current">{{ (pageObj.current - 1)*pageObj.size + scope.$index + 1 }}</span>
          <span v-else>{{ scope.$index+1 }} </span>
        </template>
      </el-table-column>
      <template v-for="(col, index) in columns">
        <slot v-if="col.slot" :name="col.slot" />
        <el-table-column
          v-else-if="col.show!==false"
          :key="index"
          :prop="col.prop"
          :label="col.label"
          :width="col.width"
          :formatter="col.formatter"
          :show-overflow-tooltip="col.overflow"
          align="center"
        />
        <template v-else-if="col.dynamic===true">
          <el-table-column
            v-for="(list,idx) in tableData.length&&tableData[0][col.columnLabelList]||[]"
            :key="`${list} && ${idx}`"
            :label="list"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{ Object.keys(scope.row[col.prop]).length?
                scope.row[col.prop][list]&&scope.row[col.prop][list][col.dynamicValue]:'' }}</span>
            </template>
          </el-table-column>
        </template>

      </template>
    </el-table>

  </div>
</template>

<script>
export default {
  name: 'DataTable',
  props: {
    expandData: {
      type: String,
      default: 'expandData'
    },
    selectExpandInit: {
      type: Function,
      default: () => {
        return {}
      }
    },
    selectInit: {
      type: Function,
      default: () => {
        return {}
      }
    },
    cellStyle: {
      type: Function,
      default: () => {
        return {}
      }
    },
    getSummaries: {
      type: Function,
      default: () => {
        return {}
      }
    },
    expand: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    showIndex: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    check: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    showExpandCheck: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    maxHeight: {
      type: String,
      default: () => {
        return '550px'
      }
    },
    reserveSelection: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    showSummary: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    width: {
      type: Number,
      default: () => {
        return 0
      }
    },

    tableData: {
      type: Array,
      default() {
        return []
      }
    },
    columns: {
      type: Array,
      default() {
        return []
      }
    },
    expandColumns: {
      type: Array,
      default() {
        return []
      }
    },
    loading: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    pageObj: {
      type: Object,
      default() {
        return {}
      }
    },
    objectSpanMethod: {
      type: Function,
      default: ({ row, column, rowIndex, columnIndex }) => {
        return {
          rowspan: 1,
          colspan: 1
        }
      }
    }
  },
  methods: {
    handleTableExpandChange(val) {
      this.$emit('handleTableExpandChange', val)
    },
    cellClass(row) {
      if (row.columnIndex === 0) {
        return 'disableheadselection'
      }
    },
    select(rows, row) {
      const obj = { rows, row }
      this.$emit('select', obj)
    },
    selectExpandData(rows, row) {
      const obj = { rows, row }
      this.$emit('selectExpandData', obj)
    },
    selectAll(selection) {
      this.$emit('selectAll', selection)
    },
    handleSelectionChange(val) {
      this.$emit('handleSelectionChange', val)
    },
    handleSelectionExpandChange(val) {
      this.$emit('handleSelectionExpandChange', val)
    },
    rowKey(row) {
      return row.defaultKey || row.id || row.purchaseOrderCode
    },
    isShowExpend(row, index) {
      if (row.row[this.expandData] && row.row[this.expandData].length === 0) {
        return 'hide-expend'
      }
      if (row.row.errorMsg) {
        return 'warning-row'
      }
      return ''
    }
  }

}
</script>
<style scoped lang="scss">
/deep/.my_table {
  .el-table {
    td,th{
      border-bottom:none!important;
    }

  }
}
/deep/ .hide-expend .el-table__expand-icon {
  display: none;
}
/deep/ .el-table .warning-row {
  color: red !important;
}
/deep/ .disableheadselection > .cell .el-checkbox__inner {
	display: none!important;
	}
</style>
